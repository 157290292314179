<template>
  <div class="not-found">
    <div class="msg-box">
      <h1 class="animated fadeInDown">Success</h1>
      <pre>{{route}}</pre>
      <!-- <h2 class="animated fadeInUp">
        Oops!<br>It seems that this page does not exist.
      </h2> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',

  data () {
    return {
      route: {
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        meta: this.$route.meta,
        hash: this.$route.hash,
        fullPath: this.$route.fullPath,
        // matched: this.$route.matched,
        name: this.$route.name,
        redirectedFrom: this.$route.redirectedFrom
      }
    }
  },

  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>
